import { render, staticRenderFns } from "./Change.vue?vue&type=template&id=5b2c51f1&scoped=true&"
import script from "./Change.vue?vue&type=script&lang=js&"
export * from "./Change.vue?vue&type=script&lang=js&"
import style0 from "../../css/FindPw.css?vue&type=style&index=0&id=5b2c51f1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2c51f1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VTextField})
