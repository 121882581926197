<template>
  <div id="Camera">
    <camera-setting></camera-setting>
  </div>
</template>

<script>
import CameraSetting from '@/components/WebCam/CameraSetting';
export default {
  name : "Camera",
  components : {
    CameraSetting,
  },
}
</script>

<style>

</style>